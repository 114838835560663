/**
 * Zeigt eine Sanduhr, wenn eine Seite geladen wird
 * @returns
 */
function Spinner () {
	return (
		<div className="spinner">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
	);
}

export default Spinner;
