import { Link } from "react-router-dom";

function Leistungen() {
  return (
    <>
      <h1>Folgende Leistungen biete ich an</h1>
      <article className="row mb-4">
        <div className="col-12">
          Schuster bleib bei Deinen leisten. Viele Mitbewerber bieten alles an. F&uuml;r mich gibt es klar definierte Grenzen, so dass bestimmte Dinge nicht durch mich
          realisiert werden k&ouml;nnen. Dies hei&szlig;t aber nicht, dass Ihnen nicht geholfen werden kann. Durch meine langj&auml;hrige Erfahrung konnte ich ein Netzwerk
          von Webdesigner, Beratern und SEO Spezialisten aufbauen.
        </div>
      </article>

      <article className="row mb-4">
        <div className="col-12">
          <h2>Analyse und Beratung</h2>
          <p>
            Sind Sie nicht gl&uuml;cklich mit Ihrem aktuellem Projekt, haben sich die Anforderungen ge&auml;ndert?<br/>
            Mit der Zeit merk man als Benutzer, dass getroffene Entscheidungen nicht immer die besten waren.<br/>
            Es stellt sich die Frage, wie geht es weiter? Soll ich mein Projekt fortf&uuml;hren oder w&auml;re es sinnvoller bei Null zu beginnen
          </p>
          <p>
            Ich helfe Ihnen auf Basis einer Analyse des Ist-Zustandes eine Entscheidung zu f&auml;llen,
            die f&uuml;r Sie aus wirtschaftlicher Sicht umsetzbar und auch zukunftsicher ist.<br/>
            Manchmal reichen schon kleine Anpassungen um Gro&szlig;es zu bewirken.
          </p>
          <p><Link to="/leistungen/anylyse-und-beratung" title="Analyse und Beratung">Analyse und Beratung</Link></p>
        </div>
      </article>

      <article className="row mb-4">
        <div className="col-12">
          <h2>Individual Programmierung</h2>
          <p>
            Im Bereich der individual Programmierung kommen immer wieder die gleichen Fragen auf.<br/>
            Gibt es das denn nicht schon als fertige L&ouml;sung?<br/>
            Was kostet das?
          </p>
          <p>
            Es gibt mitlerweile sehr viele Softwarel&ouml;sungen f&uuml;r alle m&ouml;glichen Problemstellungen.<br/>
            Die Auswahl reicht von lizensierter Software, &uuml;ber openSource bis zu freier Software.<br/>
            Alle gemeinsam haben Ihre Kosten, wie auch Ihre Vor- und Nachteile.
          </p>
          <p>
            Die Vorteile einer eigenen Software sind, dass au&szlig;er der Entwicklungs- und Wartungskosten keine weiteren
            Kosten anfallen. Ebenso ist die Software auf Sie zugeschnitten.
          </p>
          <p><Link to="/leistungen/programmierung" title="Mehr zum Thema Programmierung">Mehr zum Thema Programmierung</Link></p>
          <section>
            <h3>Datenportierung</h3>
            <p>
            </p>
            <p><Link to="/leistungen/programmierung/datenportierung-und-migration" title="Mehr zum Thema Datenportierung">Mehr zum Thema Datenportierung</Link></p>
          </section>
          <section>
            <h3>Schnittstellen</h3>
            <p>
            </p>
            <p><Link to="/leistungen/programmierung/schnittstellen" title="Mehr zum Thema Schnittstellen">Mehr zum Thema Schnittstellen</Link></p>
          </section>
          <section>
            <h3>Individualsoftware</h3>
            <p><Link to="/leistungen/individual-software" title="Mehr zum Thema Individualsoftware">Mehr zum Thema Individualsoftware</Link></p>
          </section>
        </div>
      </article>

      <article className="row">
        <div className="col-12">
          <h2>Webseitenentwicklung</h2>
          <p>
            Immer mehr Relevanz nimmt die Pr&auml;sentation im Internet ein. Mitlerweile gibt es eine vielzahl an M&ouml;glichkeiten eine Webseite zu erstellen.
            Dies reicht von der klassisch HTML programmierten Seite bis zum komplexen <em>C</em>ontent <em>M</em>anagement <em>S</em>ystem. Je nach Anfoderung gilt es
            daraus die richtige Wahl zu treffen.
          </p>
          <p>
            Gerne berate ich Sie zu diesem Thema und helfe Ihnen die richtige L&ouml;sung f&uuml;r Ihre Anspr&uuml;che zu finden.
          </p>
          <p><Link to="/leistungen/webseite" title="Mehr zum Thema Webseitenentwicklung">Mehr zum Thema Webseitenentwicklung</Link></p>
          <section>
            <h3>CM Systeme</h3>
            <ul>
              <li><Link to="/leistungen/webseite/wordpress" title="WordPress">WordPress</Link></li>
              <li><Link to="/leistungen/webseite/typo3" title="Typo3">Typo3</Link></li>
              <li><Link to="/leistungen/webseite/templates" title="Umsetzung von Designs">Umsetzung von Designs</Link></li>
              <li><Link to="/leistungen/webseite/plugins" title="Modul- / Pluginentwicklung">Modul- / Pluginentwicklung</Link></li>
            </ul>
            <p><Link to="/leistungen/webseite/cms" title="Mehr zum Thema CMS">Mehr zum Thema CMS</Link></p>
          </section>
          <section>
            <h3>Shopsysteme</h3>
            <ul>
              <li><Link to="/leistungen/ecommerce/modified-shop" title="Modified Shop">Modified Shop</Link></li>
              <li><Link to="/leistungen/ecommerce/shopware" title="Modified Shop">Shopware</Link></li>
              <li><Link to="/leistungen/ecommerce/gambio" title="Gambio">Gambio</Link></li>
              <li><Link to="/leistungen/ecommerce/woo-commerce" title="Gambio">Woo Commerce</Link></li>
              <li><Link to="/leistungen/ecommerce/template" title="Templateerstellung">Templateerstellung</Link></li>
              <li><Link to="/leistungen/ecommerce/shop-modul" title="Mehr zum Thema Individualprogrammierung">Shop Modul Entwicklung</Link></li>
            </ul>
            <p><Link to="/leistungen/ecommerce" title="Mehr zum Thema Onlineshops">Mehr zum Thema Onlineshops</Link></p>
          </section>
          <section>
            <h3>Eigenentwicklung</h3>
            <ul>
              <li><Link to="/leistungen/webseite/visitenkarte" title="Web Visitenkarte">Web Visitenkarte</Link></li>
              <li><Link to="/leistungen/webseite/onepager" title="One Pager">One Pager</Link></li>
            </ul>
            <p><Link to="/leistungen/webseite/individuelle" title="Mehr zum Thema Eigenentwicklung">Mehr zum Thema Eigenentwicklung</Link></p>
          </section>
        </div>
      </article>
    </>
  );
}

export default Leistungen;
