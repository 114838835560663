import Footer from "./components/Footer";
import Header from "./components/Header";
import { AppRoutes } from "./routes/routes";

function App() {
  return (
    <>
      <Header />

      <main className="main">
        <AppRoutes />
      </main>

      <Footer />
    </>
  );
}

export default App;
