import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Impressum() {

  const { t } = useTranslation();

  return (
    <div className="row">
      <h1>Impressum</h1>
      <p><strong>Angaben gem&auml;&szlig; &sect; 5 TMG</strong></p>

	    <div className="col-12 col-lg-6">
		    <h2>Anschrift</h2>
		    <address>
	        {t('contact.name')}<br/>
	        {t('contact.street')}<br/>
	        {t('contact.zip')} {t('contact.city')}
	      </address>
		    <h2>Steuernummer</h2>
		    <p>{t('contact.tax')}</p>
		    <h2>Internet</h2>
		    <p><Link to="https://www.dev-man.de" title="www.dev-man.de" rel="index">www.dev-man.de</Link></p>
		    <h2>E-Mail</h2>
		    <p><Link to={`mailto:${t('contact.email')}`} title="Kontaktemail" rel="index">{t('contact.email')}</Link></p>
		    <h2>Inhaber und Verantwortlicher der Webseite</h2>
		    <p>{t('contact.name')}</p>
		    <h2>Angaben zur Berufshaftpflichtversicherung</h2>
		    <p>
          <Link to="https://www.exali.de/siegel/David-Jacob" rel="nofollow" target="_blank" title="Weitere Informationen zur IT-Haftpflicht von David Jacob, Hoppegarten">
            <img src="//siegel.exali.de/siegel/Haftpflicht_Siegel_1_8b6a7f6e415fea0561ad5d9b1b1ad608.png" width="65" height="65" alt="Siegel IT-Haftpflicht von David Jacob, Hoppegarten" />
          </Link>
		    </p>
	    </div>
	    <div className="col-12 col-lg-6">
		    <h2>Allgemeiner Haftungsausschluss</h2>
		    <h3>Inhalt des Onlineangebotes</h3>
        <p>
          Der Autor &uuml;bernimmt keinerlei Gew&auml;hr f&uuml;r die Aktualit&auml;t, Korrektheit, Vollst&auml;ndigkeit oder Qualit&auml;t der bereitgestellten Informationen. Haftungsanspr&uuml;che gegen den Autor, welche sich auf Sch&auml;den materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollst&auml;ndiger Informationen verursacht wurden sind grunds&auml;tzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vors&auml;tzliches oder grob fahrl&auml;ssiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor beh&auml;lt es sich ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder endg&uuml;ltig einzustellen.
        </p>
        <h3>Urheber- und Kennzeichenrecht</h3>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zur&uuml;ckzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte gesch&uuml;tzten Marken- und Warenzeichen unterliegen uneingeschr&auml;nkt den Bestimmungen des jeweils g&uuml;ltigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigent&uuml;mer. Allein aufgrund der blo&szlig;en Nennung ist nicht der Schlu&szlig; zu ziehen, dass Markenzeichen nicht durch Rechte Dritter gesch&uuml;tzt sind! Das Copyright f&uuml;r ver&ouml;ffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielf&auml;ltigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdr&uuml;ckliche Zustimmung des Autors nicht gestattet.
        </p>
        <h3>Rechtswirksamkeit dieses Haftungsausschlusses</h3>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollst&auml;ndig entsprechen sollten, bleiben die &uuml;brigen Teile des Dokumentes in ihrem Inhalt und ihrer G&uuml;ltigkeit davon unber&uuml;hrt.
        </p>
        <h3>Kontaktdaten Schutz</h3>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten durch Dritte zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdr&uuml;cklich widersprochen. Der Betreiber der Seiten beh&auml;lt sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Werbeanrufe, Spam-Mails, vor.
        </p>
	    </div>
    </div>
  );
}

export default Impressum;
