import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import wpApi from "../axios/fetchPosts";

function Referenzen() {

  const [data, setData] = useState();

  useEffect(() => {
    let mounted = true;

    /*
    (async() => {
      setData(await wpApi.)
    })();
    */

    return () => { mounted = false; };
  }, []);


  return (
    <>
      <h1>Eine Auswahl von Referenzen</h1>
      <p>Die Nachfolgenden Referenzen sind ein Auszug von Projekten, die von mir selbst bzw. im Auftrag von Partnern realisiert wurden.</p>
      <div className="row mb-4">
        <h2 className="col-12 mb-4">Projekte, die in Zusammenarbeit mit der Agentur <Link to="https://www.dailyseven.de" title="Internetagentur dailyseven" target="_blank">dailyseven</Link> realisiert wurden.</h2>
        <div className="col-12">

          <div className="accordion" id="refenzen_partner">

            <div className="card">
              <div className="card-header" id="headingGenanet" data-toggle="collapse" data-target="#genanet" aria-expanded="true" aria-controls="genanet">
                <h2>Genanet</h2>
              </div>
              <div id="genanet" className="collapse show" aria-labelledby="headingGenanet" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung und Wartung der Webseite</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/genanet_thumb.png" alt="Genanet"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Typo3</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des CMS auf aktuelle Version</li>
                          <li className="list-group-item">Umstellung von CSS Styled Content auf Fluid Styled Content</li>
                          <li className="list-group-item">Wartung der Webseite</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://genanet.de" target="_blank" title="Genanet">Genanet</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingGenderCC" data-toggle="collapse" data-target="#gendercc" aria-expanded="true" aria-controls="gendercc">
                <h2>GenderCC</h2>
              </div>
              <div id="gendercc" className="collapse" aria-labelledby="headingGenderCC" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung und Wartung der Webseite (Typo3 CMS)</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/gendercc_thumb.png" alt="GenderCC"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Typo3</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des CMS auf aktuelle Version</li>
                          <li className="list-group-item">Umstellung von CSS Styled Content auf Fluid Styled Content</li>
                          <li className="list-group-item">Wartung der Webseite</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://gendercc.net" target="_blank" title="GenderCC">GenderCC</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingFawas" data-toggle="collapse" data-target="#fawas" aria-expanded="true" aria-controls="fawas">
                <h2>Fawas GmbH</h2>
              </div>
              <div id="fawas" className="collapse" aria-labelledby="headingFawas" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <h3 className="mb-4">Aktualisierung des Webshops und Anpassungsprogrammierungen f&uuml;r die Shops</h3>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/Fawas_Shop_thumb.png" alt="Fawas Shop DE / AT"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung der modified Shops f&uuml;r DE / AT (1.0.6 nach 2.0.4.2)</li>
                          <li className="list-group-item">XML Schnittstelle f&uuml;r Bestellungen zum ERP</li>
                          <li className="list-group-item">HTML Template &uuml;berarbeitet</li>
                          <li className="list-group-item">Erweiterung der Produktsuche</li>
                          <li className="list-group-item">Umstellung auf UTF-8</li>
                          <li className="list-group-item">Umstellung auf https</li>
                          <li className="list-group-item">Support bei Einrichtung der neuen Schnittstelle des Zahlungsanbieters PayPal</li>
                        </ul>
                      </div>
                    </div>
                    <h3 className="my-4">Relaunch der Webshops f&uuml; AT / DE und Anbindung an den Blog (aktuell)</h3>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2">
                        <img src="images/referenzen/thumbs/fawas_relaunch_thumb.png" alt="Fawas Shop DE / AT"/>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung der Shops</li>
                          <li className="list-group-item">Anbindung an externen Blog</li>
                          <li className="list-group-item">Kompletter Relaunch des Shops</li>
                          <li className="list-group-item">Megamen&uuml;</li>
                          <li className="list-group-item">Responsive Design</li>
                        </ul>
                      </div>
                      <div className="col-12 my-4"><Link to="https://fawas-shop.de" target="_blank" title="Fawas GmbH">Fawas - Saubere und gesunde Lebensr&auml;me</Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingSchwarzkopf" data-toggle="collapse" data-target="#schwarzkopf" aria-expanded="false" aria-controls="schwarzkopf">
                <h2>Schwarzkopf Stiftung</h2>
              </div>
              <div id="schwarzkopf" className="collapse" aria-labelledby="headingSchwarzkopf" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Relaunch der Webseite Schwarzkopfstiftung Junges Europa (WordPress)</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/schwarzkopf_thumb.png" alt="Schwarzkopfstiftung Junges Europa"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des vorgegebenen Layouts mittels Bridge Child Theme</li>
                          <li className="list-group-item">Datenportierung von Ausgangsseite</li>
                          <li className="list-group-item">Portierung der vorhandenen Plugins</li>
                          <li className="list-group-item">Mehrsprachigkeit via WPML</li>
                          <li className="list-group-item">Veranstaltungskalender</li>
                          <li className="list-group-item">Informationsmaterialbereitstellung (Anpassungsprogrammierung)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingBRST" data-toggle="collapse" data-target="#brst" aria-expanded="false" aria-controls="brst">
                <h2>B&uuml;rgermeister Reuter Stiftung</h2>
              </div>
              <div id="brst" className="collapse" aria-labelledby="headingBRST" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Relaunch der Webseite f&uuml;r die Seite B&uuml;rgermeister Reuter Stiftung (WordPress)</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/BRST_thumb.png" alt="B&uuml;rgermeister Reuter Stiftung"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des vorgegebenen Layouts mittels Bridge Theme</li>
                          <li className="list-group-item">Mehrsprachigkeit via WPML</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.brst.de" target="_blank" title="BRST">B&uuml;rgermeister Reuter Stiftung</Link></div>
                    <div className="row mb-4">
                      <h3 className="col-12">Relaunch der Webseite f&uuml;r die Seite House of Nations (WordPress)</h3>
                      <h4 className="col-12">Auftrag: dailyseven</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/HON_thumb.png" alt="House of Nations"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des vorgegebenen Layouts mittels Bridge Theme</li>
                          <li className="list-group-item">Mehrsprachigkeit via WPML</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.house-of-nations.de" target="_blank" title="HON">House of Nations</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingFSA" data-toggle="collapse" data-target="#fsa" aria-expanded="false" aria-controls="fsa">
                <h2>FSA Gerken</h2>
              </div>
              <div id="fsa" className="collapse" aria-labelledby="headingFSA" data-parent="#refenzen_partner">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Neuerstellugn der Webseite f&uuml;r die Seite von FSA Gerken (WordPress)</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/fsa_gerken_thumb.png" alt="FSA Gerken"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des vorgegebenen Layouts mittels Bridge Child Theme</li>
                          <li className="list-group-item">Besonderheiten der Men&uuml;f&uuml;hrung des Kunden komplett eingebunden</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <p>
            In der langj&auml;hrigen Zusammenarbeit mit <Link to="https://www.dailyseven.de" target="_blank" title="Web Design in und aus Berlin">Hr. Koina</Link> wurden noch viele weitere Themen bearbeitet und individuelle L&ouml;sungen entwickelt.
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <h2 className="col-12 mb-4">Von mir betreute Projekte</h2>
        <div className="col-12">
          <div className="accordion" id="refenzen_projekte">

            <div className="card">
              <div className="card-header" id="headingCommpassPlus" data-toggle="collapse" data-target="#compassplus" aria-expanded="true" aria-controls="compassplus">
                <h2>compassPlus GmbH</h2>
              </div>
              <div id="compassplus" className="collapse show" aria-labelledby="headingCommpassPlus" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Wartung der Plattform</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/microparticles_shop_thumb.png" alt="Microparticles GmbH Shop"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Individual Programmierung</p>
                        <ul className="list-group">
                          <li className="list-group-item">Wartung der Plattform</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headinghalawe" data-toggle="collapse" data-target="#halawe" aria-expanded="true" aria-controls="halawe">
                <h2>Hanf Labyrinth Wetterau GbR</h2>
              </div>
              <div id="halawe" className="collapse" aria-labelledby="headinghalawe" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung einer Webseite (WordPress)</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/halawe_thumb.png" alt="HALAWE"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Erstellung einer Webseite nach Layout Vorgabe</li>
                          <li className="list-group-item">Einrichtung aller zugeh&ouml;rigen Dienste wie E-Mail, Social Media etc.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.halawe.de" target="_blank" title="HALAWE">Hanf Labyrinth Wetterau</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingMicroparticles" data-toggle="collapse" data-target="#microparticles" aria-expanded="true" aria-controls="microparticles">
                <h2>Microparticles GmbH</h2>
              </div>
              <div id="microparticles" className="collapse" aria-labelledby="headingMicroparticles" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung und Wartung des Webshops</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/microparticles_shop_thumb.png" alt="Microparticles GmbH Shop"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des Webshops (1.06 nach 2.0.4.2)</li>
                          <li className="list-group-item">Umstellung auf UTF-8</li>
                          <li className="list-group-item">Anpassung des Templates</li>
                          <li className="list-group-item">Modul f&uuml;r Versand via Boten entwickelt</li>
                          <li className="list-group-item">Wartung des Shops</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.microparticles-shop.de" target="_blank" title="microParticles GmbH">microParticles GmbH Webshop</Link></div>
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung und Wartung der Webseite von Mircoparticles</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/microparticles_thumb.png" alt="Microparticles GmbH"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Childtheme auf Basis des Themes twentyfourteen entwickelt</li>
                          <li className="list-group-item">Responsive Design entwickelt</li>
                          <li className="list-group-item">Mehrsprachigkeit mittels WPML umgesetzt</li>
                          <li className="list-group-item">Plugin zur Shopanbindung</li>
                          <li className="list-group-item">Backend Editor zur leichteren Seitenbearbeitung angepasst</li>
                          <li className="list-group-item">Newsticker</li>
                          <li className="list-group-item">Wartung der Webseite</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.microparticles.de" target="_blank" title="microParticles GmbH">microParticles GmbH</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingEdelholz" data-toggle="collapse" data-target="#edelholz" aria-expanded="false" aria-controls="edelholz">
                <h2>Edelholzverkauf</h2>
              </div>
              <div id="edelholz" className="collapse" aria-labelledby="headingEdelholz" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung des Shops sowie Support und Wartung</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/edelholz_thumb.png" alt="Edelholzverkauf"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des Webshops (1.06 nach 2.0.4.2)</li>
                          <li className="list-group-item">Umstellung auf UTF-8</li>
                          <li className="list-group-item">Einrichtung von Cronjobs f&uuml;r den Kunden</li>
                          <li className="list-group-item">Anpassung aller alten Plugins, so dass diese mit neuer Shopversion kompatibel sind</li>
                          <li className="list-group-item">Entwicklung von Modulen f&uuml;r Sondergr&ouml;&szlig;versand, speziellen Exportlisten f&uuml;r gesch&uuml;tze Holzarten</li>
                          <li className="list-group-item">Entwicklung einer L&ouml;sung zur Verwaltung von tempor&auml;r nicht mehr aktiven Artikeln</li>
                          <li className="list-group-item">Anpassung des Backends nach Kundenwunsch (z.B. Multibilderupload via Drag'n Drop)</li>
                          <li className="list-group-item">Anbindung an DHL Labelservice inkl. Einbindung von Paket-/Poststationen und Testlabeldruck</li>
                          <li className="list-group-item">Erstellung eines responsive Templates</li>
                          <li className="list-group-item">Erstellung eines News und Galeriesystems</li>
                          <li className="list-group-item">Allg. Support</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.edelholzverkauf.de" target="_blank" title="Edelholzverkauf">Edelholzverkauf</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingStaubsauger" data-toggle="collapse" data-target="#staubsauger" aria-expanded="false" aria-controls="staubsauger">
                <h2>Staubsaugermanufaktur GmbH</h2>
              </div>
              <div id="staubsauger" className="collapse" aria-labelledby="headingStaubsauger" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <h3 className="mb-4">Aktualisierung des Shops sowie Support und Wartung</h3>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/staubsauger_thumb.png" alt="Staubsaugermanufaktur"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Portierung zwei verschiedener Shopsysteme (Shopware und Modified Shop) zu neuem Hoster</li>
                          <li className="list-group-item">Einrichtung von Cronjobs f&uuml;r den Kunden</li>
                          <li className="list-group-item">Aktualisierung des Webshops (2.0.3.0 nach 2.0.4.2)</li>
                          <li className="list-group-item">Template&uuml;berarbeitung</li>
                          <li className="list-group-item">Korrektur von fehlerhaften Javascripts</li>
                          <li className="list-group-item">Performancesteigerung</li>
                          <li className="list-group-item">Anbindung an Ebay</li>
                          <li className="list-group-item">Demn&auml;chst Anbindung an Amazon</li>
                          <li className="list-group-item">Spezielle Statistiken</li>
                          <li className="list-group-item">Anbindung an DHL Labelservice inkl. Einbindung von Paket-/Poststationen und Testlabeldruck</li>
                          <li className="list-group-item">SEO Betreuung gemeinsam mit der Internetagentur <Link to="https://www.dailyseven.de" title="SEO aus Berlin" target="_blank">dailyseven</Link></li>
                        </ul>
                      </div>
                    </div>
                    <h3 className="mb-4">Relaunch des Shops (Aktuell)</h3>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/staubsaugermanufaktur_relaunch_thumb.png" alt="Staubsaugermanufaktur Relaunch"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Relaunch des Shops</li>
                          <li className="list-group-item">Umsetzung von SEO Ma&szlig;nahmen</li>
                          <li className="list-group-item">individuelle Programmierungen</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.staubsaugermanufaktur.de" target="_blank" title="Staubsaugermanufaktur GmbH">Staubsaugermanufaktur GmbH</Link></div>
                    <h3 className="mb-4">Launch des B2B Shops staubsaugermarkt.de (Aktuell)</h3>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/staubsaugermarkt_thumb.png" alt="Staubsaugermarkt"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Erstellung eines B2B Shops</li>
                          <li className="list-group-item">individuelle Programmierungen</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.staubsaugermarkt.de" target="_blank" title="Staubsaugermarkt">Staubsaugermarkt</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingTctrading" data-toggle="collapse" data-target="#tctrading" aria-expanded="false" aria-controls="tctrading">
                <h2>TC Trading Berlin</h2>
              </div>
              <div id="tctrading" className="collapse" aria-labelledby="headingTctrading" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung des Shops</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/tctrading_thumb.png" alt="TC Trading Berlin"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Gambio</p>
                        <ul className="list-group">
                          <li className="list-group-item">Shop aktualisiert (GX2 nach GX3)</li>
                          <li className="list-group-item">Umstellung auf https</li>
                          <li className="list-group-item">Vorhandenes Theme auf neue Version portiert</li>
                          <li className="list-group-item">Support bei Einrichtung der neuen Schnittstelle des Zahlungsanbieters PayPal</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingCrabstyle" data-toggle="collapse" data-target="#crabstyle" aria-expanded="false" aria-controls="crabstyle">
                <h2>Crabstyle</h2>
              </div>
              <div id="crabstyle" className="collapse" aria-labelledby="headingCrabstyle" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Portierung eines vorhandenen Shopsystems</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/crabstyle_thumb.png" alt="Crabstyle"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;modified Shop</p>
                        <ul className="list-group">
                          <li className="list-group-item">Portierung des Shops von XT:Commerce 3.04 SP2 nach Modified Shop (2.0.4.2)</li>
                          <li className="list-group-item">Umstellung auf UTF-8</li>
                          <li className="list-group-item">Umstellung auf https</li>
                          <li className="list-group-item">Portierung des Templates</li>
                          <li className="list-group-item">Portierung von alten Plugins, so dass diese mit Modified Shop 2 kompatibel sind</li>
                          <li className="list-group-item">&Uuml;berarbeitung des Men&uuml;s</li>
                          <li className="list-group-item">Individuelle Kategorien auf Startseite</li>
                          <li className="list-group-item">Support bei Einrichtung der neuen Schnittstelle des Zahlungsanbieters PayPal</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.crabstyle.de" target="_blank" title="Crabstyle">Crabstyle</Link></div>
                    <div className="row mb-4">
                      <h3 className="col-12">Aktualisierung des vorhandenen Blogs</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/crabstyle_thumb.png" alt="Crabstyle"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des Webblogs</li>
                          <li className="list-group-item">Umstellung auf https</li>
                          <li className="list-group-item">Korrektur fehlender Links bzw. Anpassung dieser</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingSmartiimmo" data-toggle="collapse" data-target="#smartimmo" aria-expanded="false" aria-controls="smartimmo">
                <h2>Smart Immofinance</h2>
              </div>
              <div id="smartimmo" className="collapse" aria-labelledby="headingSmartiimmo" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Wartung und Anpassungen am vorhandenen System</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/smartimmo_thumb.png" alt="Smartimmofinance"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;WordPress</p>
                        <ul className="list-group">
                          <li className="list-group-item">Optimierungen am CMS</li>
                          <li className="list-group-item">Plugin: Finanzierungsrechner erstellt</li>
                          <li className="list-group-item">Zusammenarbeit mit <Link to="https://www.argoberlin.de" title="SEO ARGO Berlin" target="_blank">SEO Agentur</Link> zur stetigen Verbesserung der Webseite</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://www.smartimmofinance.de" target="_blank" title="Smartimmofinance">Smartimmofinance</Link></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingHON" data-toggle="collapse" data-target="#hon" aria-expanded="false" aria-controls="hon">
                <h2>House of Nations</h2>
              </div>
              <div id="hon" className="collapse" aria-labelledby="headingHON" data-parent="#refenzen_projekte">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Entwicklung und Wartung einer Buchungsschnittstelle</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/HON_reser_thumb.png" alt="House of Nations"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Individual Programmierung</p>
                        <ul className="list-group">
                          <li className="list-group-item">Anbindung an vorhandenes Buchungssystem geschaffen</li>
                          <li className="list-group-item">Komplette Maske zur Reservierung erstellt und dem CI der Webseite angeglichen</li>
                          <li className="list-group-item">Administrationsmaske zur Textverwaltung erstellt</li>
                          <li className="list-group-item">Wartung und Anpassungen in Zusammenarbeit mit <Link to="https://smartvertical.com/" target="_blank" title="Smart Vertical">Smart Vertical</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 my-4"><Link to="https://reservation.houseofnations.de" target="_blank" title="Buchungsportal HON">House of Nations Zimmerreservierung</Link></div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className="row">
        <h2 className="col-12 mb-4">Projekte, die innerhalb meiner Anstellung von mir realisiert wurden</h2>
        <div className="col-12">

          <div className="accordion" id="refenzen_anstellung">

            <div className="card">
              <div className="card-header" id="headingSeng" data-toggle="collapse" data-target="#seng" aria-expanded="true" aria-controls="seng">
                <h2>RV Seng GmbH</h2>
              </div>
              <div id="seng" className="collapse show" aria-labelledby="headingOne" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung eines Preisfindungssystem</h3>
                      <h4 className="col-12">Auftrag: Einsatzb&uuml;ro / Lorop GmbH</h4>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/seng_tool_thumb.png" alt="Preistool"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Individual Programmierung</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des Layouts f&uuml;r die verschiedenen Masken</li>
                          <li className="list-group-item">Design und Erstellung des Datenbanklayouts</li>
                          <li className="list-group-item">Erstellung diverser Importe und Exporte</li>
                          <li className="list-group-item">Stetige Weiterentwicklung des Systems insbesondere im Bereich Performance, Usability und Funktionalit&auml;t</li>
                        </ul>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung eines Lagerverwaltungssystem</h3>
                      <h4 className="col-12">Auftrag: Einsatzb&uuml;ro / Lorop GmbH</h4>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/seng_lager_thumb.png" alt="Lagertool"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Individual Programmierung</p>
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des Layouts f&uuml;r die verschiedenen Masken</li>
                          <li className="list-group-item">Design und Erstellung des Datenbanklayouts</li>
                          <li className="list-group-item">Integration eines Barcode Systems</li>
                          <li className="list-group-item">Stellplatzverwaltung inklusive einer Mengenbegrenzung</li>
                          <li className="list-group-item">Sperrung von Stellpl&auml;tzen</li>
                          <li className="list-group-item">Sperrung von Best&auml;nden</li>
                          <li className="list-group-item">"Chaotisches" Lagersystem, welches durch das System verwaltet wird</li>
                          <li className="list-group-item">Inventurfunktionalit&auml;t</li>
                          <li className="list-group-item">Lager&uuml;bersicht</li>
                          <li className="list-group-item">Mehrere Lagerstandorte</li>
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row mb-4">
                        <h3 className="col-12">Erstellung eines Bestands- und Auftragsabgleichs</h3>
                        <h4 className="col-12">Auftrag: Einsatzb&uuml;ro / Lorop GmbH</h4>
                      </div>
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/seng_lager_02_thumb.png" alt="Lagerimporter"/></div>
                      <div className="col-12 col-lg-6">
                        <p><strong>System:</strong>&nbsp;Individual Programmierung</p>
                        <ul className="list-group">
                          <li className="list-group-item">Erstellung eines Programms zur Konfiguration des Importes sowie zur Ausgabe des Importstatus</li>
                          <li className="list-group-item">Import von Auftr&auml;gen (Einlagerung, Auslagerung, Stornierungen, Inventur) aus der <Link to="https://www.plusfakt.de/" title="Plusfakt" target="_blank">Warenwirtschaft</Link></li>
                          <li className="list-group-item">Automatische Abbuchung vom Lagerbestand</li>
                          <li className="list-group-item">Laufwegoptimierung</li>
                          <li className="list-group-item">Optimierung zur Aufl&ouml;sung von "kleinen" Mengen</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingHaker" data-toggle="collapse" data-target="#haker" aria-expanded="true" aria-controls="haker">
                <h2>Antiquariat Haker</h2>
              </div>
              <div id="haker" className="collapse" aria-labelledby="headingHaker" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung eines Shop- und Contentmanagementsystems (PHP,MySQL,jQuery)</h3>
                      <h4 className="col-12">Auftrag: Lorop GmbH</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/haker_thumb.png" alt=""/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des Layouts</li>
                          <li className="list-group-item">Responsive Design</li>
                          <li className="list-group-item">Erstellung eines Backends zur Contentpflege</li>
                          <li className="list-group-item">Erstellung eines Backends zur Bestellverwaltung</li>
                          <li className="list-group-item">Erstellung eines Backends zur Artikelverwaltung</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingCompetitionline" data-toggle="collapse" data-target="#competitionline" aria-expanded="false" aria-controls="competitionline">
                <h2>competitionline</h2>
              </div>
              <div id="competitionline" className="collapse" aria-labelledby="headingCompetitionline" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Optimierung eines Newsletters (HTML,NODE.JS)</h3>
                      <h4 className="col-12">Auftrag: Einsatzb&uuml;ro</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/competitionline_thumb.png" alt="competitionline"/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Testsystem f&uuml;r E-Mailvorschau entwickelt</li>
                          <li className="list-group-item">&Uuml;berarbeitung des Newsletters hinsichtlich seiner Datenmenge</li>
                          <li className="list-group-item">&Uuml;berarbeitung des Designs f&uuml;r Outlook</li>
                          <li className="list-group-item">&Uuml;berarbeitung des Designs f&uuml;r Mobile Clienten</li>
                          <li className="list-group-item">&Uuml;berarbeitung des Designs f&uuml;r andere Desktop Clienten</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingCoppercon" data-toggle="collapse" data-target="#coppercon" aria-expanded="false" aria-controls="coppercon">
                <h2>CopperCon</h2>
              </div>
              <div id="coppercon" className="collapse" aria-labelledby="headingCoppercon" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung einer Webseite (WordPress)</h3>
                      <h4 className="col-12">Auftrag: Einsatzb&uuml;ro</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/coppercon_thumb.png" alt="Coppercon"/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des Layouts</li>
                          <li className="list-group-item">Responsive Design</li>
                          <li className="list-group-item">Mehrsprachigkeit mittels WPML</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingAchtsamkeit" data-toggle="collapse" data-target="#achtsamkeit" aria-expanded="false" aria-controls="achtsamkeit">
                <h2>Achtsamtkeitstraining</h2>
              </div>
              <div id="achtsamkeit" className="collapse" aria-labelledby="headingAchtsamkeit" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Erstellung einer Webseite (Typo3)</h3>
                      <h4 className="col-12">Auftrag: Lorop GmbH</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/achtsamkeitstraining_thumb.png" alt="Achtsamkeitstraining"/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Umsetzung des Layouts</li>
                          <li className="list-group-item">Responsive Design</li>
                          <li className="list-group-item">Einpflege der Grunddaten</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingArchitektur" data-toggle="collapse" data-target="#architektur" aria-expanded="false" aria-controls="architektur">
                <h2>Architektur Bauart</h2>
              </div>
              <div id="architektur" className="collapse" aria-labelledby="headingArchitektur" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">&Uuml;berarbeitung der Webseite (Typo3)</h3>
                      <h4 className="col-12">Auftrag: Einsatzb&uuml;ro</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/architektur-bauart_thumb.png" alt="Architektur Bauart"/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Aktualisierung des Typo3 Systems</li>
                          <li className="list-group-item">Umstellung auf https</li>
                          <li className="list-group-item">Erstellung der Datenschutzbestimmungen in Abstimmung mit Auftraggeber</li>
                          <li className="list-group-item">&Uuml;berarbeitung der Galerie</li>
                          <li className="list-group-item">Erstellung und Einbindung eines Videos</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingZeit" data-toggle="collapse" data-target="#zeit" aria-expanded="false" aria-controls="zeit">
                <h2>Zeiterfassung</h2>
              </div>
              <div id="zeit" className="collapse" aria-labelledby="headingZeit" data-parent="#refenzen_anstellung">
                <div className="card-body">
                  <div className="container">
                    <div className="row mb-4">
                      <h3 className="col-12">Neuerstellung einer Zeiterfassung (C#, MySQL)</h3>
                      <h4 className="col-12">Auftrag: Lorop GmbH</h4>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2"><img src="images/referenzen/thumbs/zeiterfassung_thumb.png" alt=""/></div>
                      <div className="col-12 col-lg-6">
                        <ul className="list-group">
                          <li className="list-group-item">Funktionalit&auml;ten des vorhandenen Systems auf das neue &Uuml;bertragen</li>
                          <li className="list-group-item">Vorlagen von MS Office zur Auswertung und Erstellung von Dokumenten nutzen</li>
                          <li className="list-group-item">Benutzerverwaltung</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
}

export default Referenzen;
