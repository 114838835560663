import { ChangeEvent, MouseEvent, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import kontaktReducer, { defaultKontakt} from "../reducer/kontakt.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function KontaktPage() {

  const { t } = useTranslation();

  const key = process.env.REACT_APP_CAPTCHA_KEY;
  const secrect = process.env.REACT_APP_CAPTCHA_KEY_SECRET;
  const secretWord = process.env.REACT_APP_CAPTCHA_ADD_WORD;

  const [data, setData] = useReducer(kontaktReducer, defaultKontakt);

  const sendFormHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    console.log(secretWord);
    let error = false;

    if (data.name === '' || data.name.length < 2) {
      document.getElementById('name')?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      document.getElementById('name')?.nextElementSibling?.classList.remove('d-block');
    }

    if (data.email === '') {
      document.getElementById('email')?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      const mailRegEx = /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/gim;
      if (!mailRegEx.exec(data.email)) {
        document.getElementById('email')?.nextElementSibling?.classList.add('d-block');
        error = true;
      } else {
        document.getElementById('email')?.nextElementSibling?.classList.remove('d-block');
      }
    }

    if (data.subject === '' || data.subject.length < 10) {
      document.getElementById('subject')?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      document.getElementById('subject')?.nextElementSibling?.classList.remove('d-block');
    }

    if (data.message === '' || data.message.length < 20) {
      document.getElementById('message')?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      document.getElementById('message')?.nextElementSibling?.classList.remove('d-block');
    }

    if (data.captchaWord === '' || data.captchaWord.toLowerCase() !== secretWord) {
      document.getElementById('captcha-word')?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      document.getElementById('captcha-word')?.nextElementSibling?.classList.remove('d-block');
    }

    if (!data.privacy) {
      document.getElementById('privacy')?.parentElement?.nextElementSibling?.classList.add('d-block');
      error = true;
    } else {
      document.getElementById('privacy')?.parentElement?.nextElementSibling?.classList.remove('d-block');
    }

    if (!error) {

    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inp = e.target.value;

    switch (e.target.name) {
      default:
        break;

      case 'name':
        data.name = inp && inp.length > 0 ? inp : '';
        break;

      case 'email':
        data.email = inp && inp.length > 0 ? inp : '';
        break;

      case 'phone':
        data.phone = inp && inp.length > 0 ? inp : '';
        break;

      case 'subject':
        data.subject = inp && inp.length > 0 ? inp : '';
        break;

      case 'message':
        data.message = inp && inp.length > 0 ? inp : '';
        break;

      case 'captcha-word':
        data.captchaWord = inp && inp.length > 0 ? inp : '';
        break;

      case 'privacy':
        if (e.target.getAttribute('checked')) {
          e.target.removeAttribute('checked');
          data.privacy = false;
        } else {
          e.target.setAttribute('checked', 'checked');
          data.privacy = true;
        }
        break;
    }

    setData({ payload: data, type: 'input' });
  }

  return (
    <div className="row">
      <h1>Folgende Kontaktm&ouml;glichkeiten stehen Ihnen zur Verf&uuml;gung</h1>
      <div className="col-12 col-lg-6">
        <h2 className="mb-4">Rufen Sie mich an oder kontaktieren Sie mich per E-Mail.</h2>
        <h3>Postanschrift</h3>
        <address className="mt-2">
          {t('contact.name')}<br/>
          {t('contact.street')}<br/>
          {t('contact.zip')} {t('contact.city')}
        </address>
        <h3>Telefonisch erreichen Sie mich unter:</h3>
        <div className="d-flex flex-row justify-content-start align-items-center my-2">
          <FontAwesomeIcon icon="phone" size="2x" className="me-3"/>
          <Link to={`tel:+49${t('contact.phone').replace(' - ', '').replace('0','')}`} title="Telefonnummer" target="_blank">{t('contact.phone')}</Link>
        </div>
        <h3>Digitale Wege zur Kontaktaufnahme:</h3>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-start align-items-center mt-2">
            <FontAwesomeIcon icon="envelope" size="2x" className="me-3"/>
            <Link to={`mailto:${t('contact.email')}`} title="Kontaktemail" target="_blank">{t('contact.email')}</Link><br/>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center mt-2">
            <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" className="me-3"/>
            <Link to="https://de.linkedin.com/in/david-jacob-824939114" target="_blank" title="Zum LinkedIn Profil">David Jacob bei LinkedIn</Link><br/>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center mt-2">
            <FontAwesomeIcon icon={['fab', 'github']} size="2x" className="me-3"/>
            <Link to="https://github.com/davejacob" target="_blank">David Jacob bei GitHub</Link>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-6">
              <figure className="figure">
                <img src="images/qr_threema.png" alt="Threma ID HEMX8EVE" width={156} height={156} className="figure-img img-fluid rounded"/>
                <figcaption className="figure-caption">Threema ID: HEMX8EVE.</figcaption>
              </figure>
            </div>
            <div className="col-6">
              <figure className="figure">
                <img src="images/qr_vcard.png" alt="Visitenkarte" width={156} height={156} className="figure-img img-fluid rounded"/>
                <figcaption className="figure-caption">Visitenkarte als QR Code.</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <h2 className="mb-4">Unverbindliche Anfrage</h2>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        <form name="kontakt">
          <div className="mt-2">
            <label htmlFor="name" className="form-label">Name:</label>*
            <input className="form-control" type="text" placeholder="Ihr Name" value={data.name} name="name" id="name" required onChange={handleChange}/>
            <div className="invalid-feedback">Bitte geben Sie Ihren Namen an</div>
          </div>
          <div className="mt-2">
            <label htmlFor="email" className="form-label">E-Mail:</label>*
            <input className="form-control" type="mail" placeholder="Ihre E-Mailadresse" value={data.email} name="email" id="email" required onChange={handleChange}/>
            <div className="invalid-feedback">Bitte geben Sie eine g&uuml;ltige E-Mailadresse an</div>
          </div>
          <div className="mt-2">
            <label htmlFor="phone" className="form-label">Telefon:</label>
            <input className="form-control" type="text" placeholder="Ihre Telefonnummer" value={data.phone} name="phone" id="phone" onChange={handleChange}/>
          </div>
          <div className="mt-2">
            <label htmlFor="subject" className="form-label">Betreff:</label>*
            <input className="form-control" type="text" placeholder="Ihr Anliegen" value={data.subject} name="subject" id="subject" required onChange={handleChange}/>
            <div className="invalid-feedback">Bitte geben Sie Ihr anliegen an</div>
          </div>
          <div className="mt-2">
            <label htmlFor="message" className="form-label">Anfrage:</label>*
            <textarea className="form-control" placeholder="Ihre Anfrage" name="message" rows={10} id="message" required value={data.message} onChange={handleChange}/>
            <div className="invalid-feedback">Teilen Sie mir in Kurzform mit, worum es sich bei Ihrem Anliegen handelt</div>
          </div>
          <div className="mt-2">
            <div className="g-recaptcha" data-sitekey={key}></div>
          </div>
          <div className="mt-2">
            <label htmlFor="captcha-word" className="form-label">Planet auf dem wir leben?</label>*
            <input id="captcha-word" className="form-control" type="text" placeholder="L&ouml;sung" value={data.captchaWord} name="captcha-word" required onChange={handleChange}/>
            <div className="invalid-feedback">Bitte geben Sie den gesuchten Planeten an</div>
          </div>
          <div className="mt-2 form-check">
            <div className="col-12">
              <input id="privacy" type="checkbox" value="1" className="form-check-input" checked={data.privacy} name="privacy" required onChange={handleChange}/>
              <label className="form-check-label" htmlFor="privacy">Ich habe <Link to='/datenschutz' target="_blank">Datenschutzerkl&auml;rung</Link> gelesen und stimme dieser zu.</label>*
            </div>
            <div className="col-12 invalid-feedback">Bitte stimmen Sie der Datenschutzerkl&auml;rung zu</div>
          </div>
          <div className="mt-2">
            <button className="btn btn-warning" type="submit" name="senden" value="1" onClick={sendFormHandler}>Unverbindliche Anfrage absenden</button>

          </div>
          <div className="mt-2">
            <small id="emailHelp" className="form-text text-muted">*Pflichtfelder</small>
          </div>
        </form>
      </div>
    </div>
  );
}

export default KontaktPage;
