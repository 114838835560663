import { Routes, Route } from 'react-router-dom';

import NotFound from '../pages/404/NotFound';
import Home from '../pages/Home';
import Impressum from '../pages/Impressum';
import Datenschutz from '../pages/Datenschutz';
import KontaktPage from '../pages/Kontakt';
import Leistungen from '../pages/Leistungen';
import Referenzen from '../pages/Referenzen';
import Notizen from '../pages/Notizen';

/*




      <Route path='/' element={<Home />} />






					<Route path='kontakt/' element={<Home />} />
					<Route path='referenzen/' element={<Home />} />

					<Route path='leistungen/' element={<Home />} />
						<Route path='analyse-und-beratung/' element={<Home />} />
						<Route path='individuelle-programmierung/' element={<Home />} />
						<Route path='datenportierung-und-migration/' element={<Home />} />
						<Route path='schnittstellen/' element={<Home />} />
						<Route path='individual-software/' element={<Home />} />
						<Route path='webseitenentwicklung/' element={<Home />} />

						<Route path='cms' element={<Home />}>
							<Route path='wordpress/' element={<Home />} />
							<Route path='typo3/' element={<Home />} />
							<Route path='templates-und-themes/' element={<Home />} />
							<Route path='erweiterungen-plugins/' element={<Home />} />
						</Route>

						<Route path='ecommerce' element={<Home />}>
							<Route path='modified-shop/' element={<Home />} />
							<Route path='shopware/' element={<Home />} />
							<Route path='templates-und-themes/' element={<Home />} />
							<Route path='erweiterungen-plugins/' element={<Home />} />
						</Route>

						<Route path='individuelle-seite' element={<Home />}>
							<Route path='one-pager/' element={<Home />} />
							<Route path='visitenkarte/' element={<Home />} />
						</Route>

					</Route>

				</Route>

    */

export const AppRoutes = () => {

	return (
    <>
    <Routes>

      {/* Fehlerseite */}
      <Route path='*' element={<NotFound />} />

      {/* Hauptseiten */}
      <Route path='/' element={<Home />} />
      <Route path='/impressum' element={<Impressum />} />
      <Route path='/datenschutz' element={<Datenschutz />} />
      <Route path='/kontakt' element={<KontaktPage />} />

      {/* Leistungen */}
      <Route path='/leistungen' element={<Leistungen />} />

      {/* Referenzen */}
      <Route path='/referenzen' element={<Referenzen />} />

      {/* Notizen */}
      <Route path='/notizen' element={<Notizen />} />

    </Routes>
    </>
	);
}
