import { Link } from "react-router-dom";

function Header() {
    return (
      <header className="container-fluid">
        <nav id="mainMenu" className="navbar navbar-expand-lg">
          <div id="logo" className="navbar-brand linkMarker">
            <Link to="/" rel="index" title="Startseite dev-man.de" target="_self">DEV-MAN<span id="tld">.DE</span></Link>
            <div id="subTitle">Softwareentwicklung mal anders</div>
          </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#hauptmenue" aria-controls="hauptmenue" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">&#9776;</span>
          </button>
          <div className="collapse navbar-collapse" id="hauptmenue">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item linkMarker"><Link to="/">Startseite</Link></li>
              <li className="nav-item linkMarker"><Link to="leistungen" className="nav-link" title="Leistungen">Leistungen</Link></li>
              <li className="nav-item linkMarker"><Link to="notizen" className="nav-link" title="Notizen">Notizen</Link></li>
              <li className="nav-item linkMarker"><Link to="referenzen" className="nav-link" title="Referenzen">Referenzen</Link></li>
              <li className="nav-item linkMarker"><Link to="kontakt" className="nav-link" title="Kontakt">Kontakt</Link></li>
            </ul>
          </div>
        </nav>
      </header>
    )
}

export default Header;
