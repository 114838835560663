import { Link } from "react-router-dom";

function Footer() {

  const now = new Date().getFullYear();

  console.log(window.URL);

  return (
    <footer className="container-fluid text-center">
      <nav className="row" id="smallMenu">
        <ul>
          <li className="nav-item linkMarker"><Link to="impressum" title="Impressum">Impressum</Link></li>
          <li className="nav-item linkMarker"><Link to="datenschutz" title="Datenschutzerkl&auml;rung">Datenschutzerkl&auml;rung</Link></li>
          <li className="nav-item linkMarker"><Link to="cookies" title="Cookie Einstellungen">Cookie Einstellungen</Link></li>
        </ul>
      </nav>
      <div>dev-man.de | 2008 - {now}</div>
    </footer>
  );
}

export default Footer;
