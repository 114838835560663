import { library } from '@fortawesome/fontawesome-svg-core';
import * as solid from '@fortawesome/free-solid-svg-icons';
import {
	faGithub,
	faGitlab,
	faLinkedin,
	faNodeJs,
	faReact,
	faShopware,
	faTypo3,
	faWordpress,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faGithub,
    faLinkedin,
    faGitlab,
    faNodeJs,
    faReact,
    solid.faEnvelope,
    solid.faPhone,

)
