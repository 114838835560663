import { Reducer } from "react";
import { Kontakt, KontaktDispatch } from "../interfaces";

// Default Authstate
export const defaultKontakt: Kontakt = {
  captcha: '',
  captchaWord: '',
  email: '',
  message: '',
  name: '',
  phone: '',
  privacy: false,
  subject: '',
};

/**
 * Stateverwaltung des Kontaktformulars
 *
 * @param {Kontakt} state Authentifizierungsstate
 * @param {AuthAction} action Aktion die ausgeführt werden soll
 * @returns
 */
const kontaktReducer: Reducer<Kontakt, KontaktDispatch> = (state: Kontakt, action: KontaktDispatch) => {

  const { payload, type } = action;

  switch (type) {

    case 'input':
    case 'send':
      return {
        ...state,
        captcha: payload.captcha,
        captchaWord: payload.captchaWord,
        email: payload.email,
        message: payload.message,
        name: payload.name,
        phone: payload.phone,
        privacy: payload.privacy,
        subject: payload.subject,
      };

    default:
      return defaultKontakt;
  }
};

export default kontaktReducer;
