function NotFound() {
  return (
    <div className="notFound">
      <h1>404 - Seite nicht gefunden</h1>
      <p>Leider wurde kein Inhalt gefunden.</p>
      <p>Entweder gibt es die Seite nicht, Du hast einen ung&uuml;ltigen Link benutzt oder Du bist ein ganz Schlimmer ;-)</p>
    </div>
  );
}

export default NotFound;
